import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/base.css"
import "./utils/pxrem"
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);


// 全局后置的路由守卫 初始化时候被调用，每次路由切换之后被调用
router.afterEach((to,from) => {//全局的后置钩子函数  一般用来显示标题，当前路由页面结束的一些功能
  document.title=to.meta.name ||"雨润集团"
})

// 初始化vue-amap

// import AMap from 'vue-amap';
// Vue.use(AMap);
// AMap.initAMapApiLoader({
//   key: "935dbc6111fe79e96ae42fcba01d760e", // 这里写你申请的高德地图的key
//   plugin: [
//   'AMap.DistrictSearch',
//   'AMap.Autocomplete', // 输入提示插件
//   'AMap.PlaceSearch', // POI搜索插件
//   'AMap.Scale', // 右下角缩略图插件 比例尺
//   'AMap.OverView', // 地图鹰眼插件
//   'AMap.ToolBar', // 地图工具条
//   'AMap.MapType', // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
//   'AMap.PolyEditor', // 编辑 折线多，边形
//   'AMap.CircleEditor', // 圆形编辑器插件
//   'AMap.Geolocation', // 定位控件，用来获取和展示用户主机所在的经纬度位置
//   'AMap.Geocoder'
// ],
// v: "1.4.15",
// uiVersion: "1.1"
// });





Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
  // bus总线的写法
  beforeCreate() {
    Vue.prototype.$bus = this //将bus添加到Vue添加构造函数的原型 全局事件总线
    
  }
}).$mount("#app");
