import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

//解决路由导航到统一路径重复报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => console.log(err))
}


const routes = [
  {
    path: '/',
    redirect: '/workOutApply'
  },
  // 出差
  {
    path: '/workOutApply',
    name: 'workOutApply',
    component: () => import("../pages/workOut-apply.vue"),
    meta: {
        fullPath: "/workOutApply",
        name: "出差举证",
    },
  },
  {
    path: '/workOutLead',
    name: 'workOutLead',
    component: () => import("../pages/workOut-lead.vue"),
    meta: {
        fullPath: "/workOutLead",
        name: "出差举证审核",
    },
  },
  {
    path: '/workOutState',
    name: 'workOutState',
    component: () => import("../pages/workOut-state.vue"),
    meta: {
        fullPath: "/workOutState",
        name: "出差流程",
    },
  },
  {
    path: '/list',
    name: 'list',
    component: () => import("../pages/workOutList.vue"),
    meta: {
        fullPath: "/list",
        name: "出差举证数据",
    },
  },
  // 加班
  {
    path: '/workOverApply',
    name: 'workOverApply',
    component: () => import("../pages/workOver-apply.vue"),
    meta: {
        fullPath: "/workOverApply",
        name: "加班信息",
    },
  },
  {
    path: '/workOverLead',
    name: 'workOverLead',
    component: () => import("../pages/workOver-lead.vue"),
    meta: {
        fullPath: "/workOverLead",
        name: "加班审核",
    },
  },
  {
    path: '/workOverState',
    name: 'workOverState',
    component: () => import("../pages/workOver-state.vue"),
    meta: {
        fullPath: "/workOverState",
        name: "加班流程",
    },
  },
  {
    path: '/workOverList',
    name: 'workOverList',
    component: () => import("../pages/workOver-list.vue"),
    meta: {
        fullPath: "/workOverList",
        name: "加班数据",
    },
  },
]

const router = new VueRouter({
  routes,
});

export default router;
